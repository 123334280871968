define("@fleetbase/fleetops-data/models/vehicle-device", ["exports", "@ember-data/model", "@ember/object", "date-fns"], function (_exports, _model, _object, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let VehicleDeviceModel = _exports.default = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('boolean'), _dec14 = (0, _model.attr)('raw'), _dec15 = (0, _model.attr)('raw'), _dec16 = (0, _model.attr)('date'), _dec17 = (0, _model.attr)('date'), _dec18 = (0, _model.attr)('date'), _dec19 = (0, _model.attr)('date'), _dec20 = (0, _model.attr)('date'), _dec21 = (0, _object.computed)('updated_at'), _dec22 = (0, _object.computed)('updated_at'), _dec23 = (0, _object.computed)('updated_at'), _dec24 = (0, _object.computed)('created_at'), _dec25 = (0, _object.computed)('created_at'), _dec26 = (0, _object.computed)('created_at'), (_class = class VehicleDeviceModel extends _model.default {
    constructor(...args) {
      super(...args);
      /** @ids */
      _initializerDefineProperty(this, "vehicle_uuid", _descriptor, this);
      _initializerDefineProperty(this, "device_id", _descriptor2, this);
      /** @attributes */
      _initializerDefineProperty(this, "device_provider", _descriptor3, this);
      _initializerDefineProperty(this, "device_type", _descriptor4, this);
      _initializerDefineProperty(this, "device_name", _descriptor5, this);
      _initializerDefineProperty(this, "device_location", _descriptor6, this);
      _initializerDefineProperty(this, "device_model", _descriptor7, this);
      _initializerDefineProperty(this, "manufacturer", _descriptor8, this);
      _initializerDefineProperty(this, "serial_number", _descriptor9, this);
      _initializerDefineProperty(this, "data_frequency", _descriptor10, this);
      _initializerDefineProperty(this, "notes", _descriptor11, this);
      _initializerDefineProperty(this, "status", _descriptor12, this);
      _initializerDefineProperty(this, "online", _descriptor13, this);
      _initializerDefineProperty(this, "meta", _descriptor14, this);
      _initializerDefineProperty(this, "data", _descriptor15, this);
      /** @dates */
      _initializerDefineProperty(this, "installation_date", _descriptor16, this);
      _initializerDefineProperty(this, "last_maintenance_date", _descriptor17, this);
      _initializerDefineProperty(this, "deleted_at", _descriptor18, this);
      _initializerDefineProperty(this, "created_at", _descriptor19, this);
      _initializerDefineProperty(this, "updated_at", _descriptor20, this);
    }
    /** @computed */
    get updatedAgo() {
      if (!(0, _dateFns.isValid)(this.updated_at)) {
        return null;
      }
      return (0, _dateFns.formatDistanceToNow)(this.updated_at);
    }
    get updatedAt() {
      if (!(0, _dateFns.isValid)(this.updated_at)) {
        return null;
      }
      return (0, _dateFns.format)(this.updated_at, 'PPP p');
    }
    get updatedAtShort() {
      if (!(0, _dateFns.isValid)(this.updated_at)) {
        return null;
      }
      return (0, _dateFns.format)(this.updated_at, 'dd, MMM');
    }
    get createdAgo() {
      if (!(0, _dateFns.isValid)(this.created_at)) {
        return null;
      }
      return (0, _dateFns.formatDistanceToNow)(this.created_at);
    }
    get createdAt() {
      if (!(0, _dateFns.isValid)(this.created_at)) {
        return null;
      }
      return (0, _dateFns.format)(this.created_at, 'PPP p');
    }
    get createdAtShort() {
      if (!(0, _dateFns.isValid)(this.created_at)) {
        return null;
      }
      return (0, _dateFns.format)(this.created_at, 'dd, MMM');
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "vehicle_uuid", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "device_id", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "device_provider", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "device_type", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "device_name", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "device_location", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "device_model", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "manufacturer", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "serial_number", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "data_frequency", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "notes", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "online", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "meta", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "data", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "installation_date", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "last_maintenance_date", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "deleted_at", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "created_at", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "updated_at", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updatedAgo", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "updatedAgo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatedAt", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "updatedAt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatedAtShort", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "updatedAtShort"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAgo", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "createdAgo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "createdAt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAtShort", [_dec26], Object.getOwnPropertyDescriptor(_class.prototype, "createdAtShort"), _class.prototype)), _class));
});