define("ember-loading/services/loading", ["exports", "@ember/service", "@ember/object", "ember-concurrency", "@ember/application", "rsvp", "ember-concurrency-ts"], function (_exports, _service, _object, _emberConcurrency, _application, _rsvp, _emberConcurrencyTs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function parseArgs() {
    let length = arguments.length;
    let args;
    let method;
    let target;
    if (length === 1) {
      target = null;
      method = arguments[0];
    } else if (length > 1) {
      let argsIndex = 2;
      let methodOrTarget = arguments[0];
      let methodOrArgs = arguments[1];
      let type = typeof methodOrArgs;
      if (type === 'function') {
        target = methodOrTarget;
        method = methodOrArgs;
      } else if (methodOrTarget !== null && type === 'string' && methodOrArgs in methodOrTarget) {
        target = methodOrTarget;
        method = target[methodOrArgs];
      } else if (typeof methodOrTarget === 'function') {
        argsIndex = 1;
        target = null;
        method = methodOrTarget;
      }
      if (length > argsIndex) {
        let len = length - argsIndex;
        args = new Array(len);
        for (let i = 0; i < len; i++) {
          args[i] = arguments[i + argsIndex];
        }
      }
    }
    return [target, method, args];
  }
  let LoadingService = _exports.default = (_class = class LoadingService extends _service.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _defineProperty(this, "postDelay", 0);
      _defineProperty(this, "preDelay", 0);
      _defineProperty(this, "watchTransitions", true);
      _defineProperty(this, "_routerTransitionDeferred", void 0);
    }
    get isLoading() {
      return (0, _emberConcurrencyTs.taskFor)(this._runJob).isRunning;
    }
    get showLoading() {
      return !(0, _emberConcurrencyTs.taskFor)(this.preDelayTask).isRunning && (this.isLoading || (0, _emberConcurrencyTs.taskFor)(this.postDelayTask).isRunning);
    }
    _routeWillChange() {
      let deferred = (0, _rsvp.defer)();
      if (this._routerTransitionDeferred) {
        this._routerTransitionDeferred.resolve();
      }
      this._routerTransitionDeferred = deferred;
      this.run(() => deferred.promise);
    }
    _routeDidChange() {
      if (this._routerTransitionDeferred) {
        this._routerTransitionDeferred.resolve();
        this._routerTransitionDeferred = undefined;
      }
    }
    init() {
      super.init();
      let config = (0, _application.getOwner)(this).resolveRegistration('config:environment')['ember-loading'];
      if (config) {
        this.preDelay = config.preDelay || 0;
        this.postDelay = config.postDelay || 0;
        this.watchTransitions = config.watchTransitions !== false;
      }
      if (this.watchTransitions) {
        this.router.on('routeWillChange', this._routeWillChange);
        this.router.on('routeDidChange', this._routeDidChange);
      }
    }
    willDestroy() {
      super.willDestroy();
      if (this.watchTransitions) {
        this.router.off('routeWillChange', this._routeWillChange);
        this.router.off('routeDidChange', this._routeDidChange);
      }
    }

    // @todo Revisit this stronger typing when https://github.com/typed-ember/ember-cli-typescript/issues/590 is resolved
    //
    // run<T, P1, P2, P3, P4, P5, P6, R>(target: T, fn: ((p1: P1, p2: P2, p3: P3, p4: P4, p5: P5, p6: P6) => R) | keyof T, p1: P1, p2: P2, p3: P3, p4: P4, p5: P5, p6: P6): Promise<R>;
    // run<T, P1, P2, P3, P4, P5, R>(target: T, fn: ((p1: P1, p2: P2, p3: P3, p4: P4, p5: P5) => R) | keyof T, p1: P1, p2: P2, p3: P3, p4: P4, p5: P5): Promise<R>;
    // run<T, P1, P2, P3, P4, R>(target: T, fn: ((p1: P1, p2: P2, p3: P3, p4: P4) => R) | keyof T, p1: P1, p2: P2, p3: P3, p4: P4): Promise<R>;
    // run<T, P1, P2, P3, R>(target: T, fn: ((p1: P1, p2: P2, p3: P3) => R) | keyof T, p1: P1, p2: P2, p3: P3): Promise<R>;
    // run<T, P1, P2, R>(target: T, fn: ((p1: P1, p2: P2) => R) | keyof T, p1: P1, p2: P2): Promise<R>;
    // run<T, P1, R>(target: T, fn: ((p1: P1) => R) | keyof T, p1: P1): Promise<R>;
    // run<T, R>(target: T, fn: (() => R) | keyof T): Promise<R>
    // run<P1, P2, P3, P4, P5, P6, R>(fn: (p1: P1, p2: P2, p3: P3, p4: P4, p5: P5, p6: P6) => R, p1: P1, p2: P2, p3: P3, p4: P4, p5: P5, p6: P6): Promise<R>;
    // run<P1, P2, P3, P4, P5, R>(fn: (p1: P1, p2: P2, p3: P3, p4: P4, p5: P5) => R, p1: P1, p2: P2, p3: P3, p4: P4, p5: P5): Promise<R>;
    // run<P1, P2, P3, P4, R>(fn: (p1: P1, p2: P2, p3: P3, p4: P4) => R, p1: P1, p2: P2, p3: P3, p4: P4): Promise<R>;
    // run<P1, P2, P3, R>(fn: (p1: P1, p2: P2, p3: P3) => R, p1: P1, p2: P2, p3: P3): Promise<R>;
    // run<P1, P2, R>(fn: (p1: P1, p2: P2) => R, p1: P1, p2: P2): Promise<R>;
    // run<P1, R>(fn: (p1: P1) => R, p1: P1): Promise<R>;
    // run<R>(fn: () => R): Promise<R>;
    // run<T, R>(target: T, fn: ((...args: any[]) => R) | keyof T, ...args: any[]): Promise<R>;
    // run<T, R>(target: T, fn: (() => R) | keyof T): Promise<R>;
    // run<R>(fn: (...args: any[]) => R, ...args: any[]): Promise<R>;
    // run<R>(fn: () => R): Promise<R>;
    async run(...args) {
      if (this.preDelay > 0) {
        (0, _emberConcurrencyTs.taskFor)(this.preDelayTask).perform(this.preDelay);
      }
      let result = await (0, _emberConcurrencyTs.taskFor)(this._runJob).perform(...args);
      if (this.postDelay > 0) {
        (0, _emberConcurrencyTs.taskFor)(this.postDelayTask).perform(this.postDelay);
      }
      return result;
    }
    *_runJob(...args) {
      let [target, method, realArgs] = parseArgs(...args);
      return yield method.apply(target, realArgs);
    }
    *preDelayTask(delay) {
      yield (0, _emberConcurrency.timeout)(delay);
    }
    *postDelayTask(delay) {
      yield (0, _emberConcurrency.timeout)(delay);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "_routeWillChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_routeWillChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_routeDidChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_routeDidChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_runJob", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "_runJob"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "preDelayTask", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "preDelayTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "postDelayTask", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "postDelayTask"), _class.prototype)), _class); // DO NOT DELETE: this is how TypeScript knows how to look up your services.
});