define("ember-leaflet/components/leaflet-map", ["exports", "@ember/component", "@ember/debug", "@ember/service", "ember-leaflet/components/base-layer", "@ember/object", "ember-leaflet/components/tile-layer", "ember-leaflet/components/wms-tile-layer", "ember-leaflet/components/marker-layer", "ember-leaflet/components/circle-layer", "ember-leaflet/components/circle-marker-layer", "ember-leaflet/components/image-layer", "ember-leaflet/components/video-layer", "ember-leaflet/components/polyline-layer", "ember-leaflet/components/polygon-layer", "ember-leaflet/components/geojson-layer", "ember-leaflet/components/rectangle-layer", "@ember/template-factory"], function (_exports, _component, _debug, _service, _baseLayer, _object, _tileLayer, _wmsTileLayer, _markerLayer, _circleLayer, _circleMarkerLayer, _imageLayer, _videoLayer, _polylineLayer, _polygonLayer, _geojsonLayer, _rectangleLayer, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each this.leafletDescriptorsProps as |prop|}}
    {{#if (ember-leaflet-eq prop "center")}}
      {{did-update (fn this.updateOption "center") this.center}}
    {{else}}
      {{did-update (fn this.updateOption prop) (get this.args prop)}}
    {{/if}}
  {{/each}}
  
  <Root @didInsertParent={{this.didInsertParent}} @willDestroyParent={{this.willDestroyParent}} ...attributes as |Node|>
    {{#let (ember-leaflet-hash) as |components|}}
      {{#each this.componentsToYield as |c|}}
        {{ember-leaflet-assign-to
          components
          key=(ember-leaflet-or c.as c.name)
          value=(component (ensure-safe-component (ember-leaflet-or c.component c.name)) parent=this node=Node)
          onChange=this.mergeComponents
        }}
      {{/each}}
  
      {{yield this.mergedComponents}}
    {{/let}}
  </Root>
  */
  {
    "id": "tTXvhRAN",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"leafletDescriptorsProps\"]]],null]],null],null,[[[41,[28,[37,3],[[30,1],\"center\"],null],[[[1,\"    \"],[1,[28,[35,4],[[28,[37,5],[[30,0,[\"updateOption\"]],\"center\"],null],[30,0,[\"center\"]]],null]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,4],[[28,[37,5],[[30,0,[\"updateOption\"]],[30,1]],null],[28,[37,6],[[30,0,[\"args\"]],[30,1]],null]],null]],[1,\"\\n\"]],[]]]],[1]],null],[1,\"\\n\"],[8,[39,7],[[17,2]],[[\"@didInsertParent\",\"@willDestroyParent\"],[[30,0,[\"didInsertParent\"]],[30,0,[\"willDestroyParent\"]]]],[[\"default\"],[[[[1,\"\\n\"],[44,[[28,[37,9],null,null]],[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"componentsToYield\"]]],null]],null],null,[[[1,\"      \"],[1,[28,[35,10],[[30,4]],[[\"key\",\"value\",\"onChange\"],[[28,[37,11],[[30,5,[\"as\"]],[30,5,[\"name\"]]],null],[50,[28,[37,13],[[28,[37,11],[[30,5,[\"component\"]],[30,5,[\"name\"]]],null]],null],0,null,[[\"parent\",\"node\"],[[30,0],[30,3]]]],[30,0,[\"mergeComponents\"]]]]]],[1,\"\\n\"]],[5]],null],[1,\"\\n    \"],[18,6,[[30,0,[\"mergedComponents\"]]]],[1,\"\\n\"]],[4]]]],[3]]]]]],[\"prop\",\"&attrs\",\"Node\",\"components\",\"c\",\"&default\"],false,[\"each\",\"-track-array\",\"if\",\"ember-leaflet-eq\",\"did-update\",\"fn\",\"get\",\"root\",\"let\",\"ember-leaflet-hash\",\"ember-leaflet-assign-to\",\"ember-leaflet-or\",\"component\",\"ensure-safe-component\",\"yield\"]]",
    "moduleName": "ember-leaflet/components/leaflet-map.hbs",
    "isStrictMode": false
  });

  /**
   * The central class of ember-leaflet — it is used to create a map on a page and manipulate it.
   *
   * @class LeafletMap
   * @extends BaseLayer
   *
   * @yield {TileLayer} tile-layer
   * @yield {WmsTileLayer} wms-tile-layer
   * @yield {MarkerLayer} marker-layer
   * @yield {CircleLayer} circle-layer
   * @yield {CircleMarkerLayer} circle-marker-layer
   * @yield {ImageLayer} image-layer
   * @yield {VideoLayer} video-layer
   * @yield {PolylineLayer} polyline-layer
   * @yield {PolygonLayer} polygon-layer
   * @yield {GeojsonLayer} geojson-layer
   * @yield {RectangleLayer} rectangle-layer
   */
  let LeafletMap = _exports.default = (_class = class LeafletMap extends _baseLayer.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "emberLeaflet", _descriptor, this);
      _defineProperty(this, "leafletOptions", [...this.leafletOptions,
      // Map state options

      /**
       * Initial geographic center of the map
       *
       * @argument center
       * @type {LatLng}
       */
      'center',
      /**
       * Initial map zoom level
       *
       * @argument zoom
       * @type {Number}
       */
      'zoom',
      /**
       * Minimum zoom level of the map.
       *
       * @argument minZoom
       * @type {Number}
       */
      'minZoom',
      /**
       * Maximum zoom level of the map.
       *
       * @argument maxZoom
       * @type {Number}
       */
      'maxZoom',
      /**
       * When this option is set, the map restricts the view to the given geographical bounds,
       * ouncing the user back if the user tries to pan outside the view.
       *
       * @argument maxBounds
       * @type {LatLngBounds}
       */
      'maxBounds',
      /**
       * The Coordinate Reference System to use. Don't change this if you're not sure what it means.
       *
       * @argument crs
       * @type {CRS}
       */
      'crs',
      // Interaction options

      /**
       * Set it to false if you don't want popups to close when user clicks the map.
       * Defaults to `true`.
       *
       * @argument closePopupOnClick
       * @type {Boolean}
       */
      'closePopupOnClick',
      /**
       * Forces the map's zoom level to always be a multiple of this.
       * By default, the zoom level snaps to the nearest integer;
       * lower values (e.g. 0.5 or 0.1) allow for greater granularity.
       * A value of 0 means the zoom level will not be snapped.
       * Defaults to `1`.
       *
       * @argument zoomSnap
       * @type {Number}
       */
      'zoomSnap',
      /**
       * Controls how much the map's zoom level will change after a zoomIn(), zoomOut(),
       * pressing + or - on the keyboard, or using the zoom controls.
       * Values smaller than 1 (e.g. 0.5) allow for greater granularity.
       * Defaults to `1`.
       *
       * @argument zoomDelta
       * @type {Number}
       */
      'zoomDelta',
      /**
       * Whether the map automatically handles browser window resize to update itself.
       * Defaults to `true`.
       *
       * @argument trackResize
       * @type {Boolean}
       */
      'trackResize',
      /**
       * Whether the map can be zoomed to a rectangular area specified by dragging
       * the mouse while pressing the shift key.
       * Defaults to `true`.
       *
       * @argument trackResize
       * @type {Boolean}
       */
      'boxZoom',
      /**
       * Whether the map can be zoomed in by double clicking on it and zoomed out
       * by double clicking while holding shift. If passed 'center', double-click zoom
       * will zoom to the center of the view regardless of where the mouse was.
       * Defaults to `true`.
       *
       * @argument doubleClickZoom
       * @type {Boolean|String}
       */
      'doubleClickZoom',
      /**
       * Whether the map be draggable with mouse/touch or not.
       * Defaults to `true`.
       *
       * @argument dragging
       * @type {Boolean}
       */
      'dragging',
      // Touch interaction options

      /**
       * Enables mobile hacks for supporting instant taps (fixing 200ms click delay
       * on iOS/Android) and touch holds (fired as contextmenu events).
       * Defaults to `true`.
       *
       * @argument tap
       * @type {Boolean}
       */
      'tap',
      /**
       * The max number of pixels a user can shift his finger during touch for it to be
       * considered a valid tap.
       * Defaults to `15`.
       *
       * @argument tapTolerance
       * @type {Number}
       */
      'tapTolerance',
      /**
       * Whether the map be draggable with mouse/touch or not.
       * Defaults to `*`.
       *
       * @argument touchZoom
       * @type {Boolean|String}
       */
      'touchZoom',
      /**
       * Set it to false if you don't want the map to zoom beyond min/max zoom
       * and then bounce back when pinch-zooming.
       * Defaults to `true`.
       *
       * @argument bounceAtZoomLimits
       * @type {Boolean}
       */
      'bounceAtZoomLimits',
      // Mouse wheel options

      /**
       * Whether the map can be zoomed by using the mouse wheel. If passed 'center',
       * it will zoom to the center of the view regardless of where the mouse was.
       * Defaults to `true`.
       *
       * @argument scrollWheelZoom
       * @type {Boolean|String}
       */
      'scrollWheelZoom',
      /**
       * Limits the rate at which a wheel can fire (in milliseconds).
       * By default user can't zoom via wheel more often than once per 40 ms.
       * Defaults to `40`.
       *
       * @argument wheelDebounceTime
       * @type {Number}
       */
      'wheelDebounceTime',
      /**
       * How many scroll pixels mean a change of one full zoom level.
       * Smaller values will make wheel-zooming faster (and vice versa).
       * Defaults to `60`.
       *
       * @argument wheelPxPerZoomLevel
       * @type {Number}
       */
      'wheelPxPerZoomLevel',
      // Keyboard navigation options

      /**
       * Makes the map focusable and allows users to navigate the map with keyboard arrows and +/- keys.
       * Defaults to `true`.
       *
       * @argument keyboard
       * @type {Boolean}
       */
      'keyboard',
      /**
       * Amount of pixels to pan when pressing an arrow key.
       * Defaults to `80`.
       *
       * @argument keyboardPanDelta
       * @type {Number}
       */
      'keyboardPanDelta',
      // Panning Inertia Options

      /**
       * If enabled, panning of the map will have an inertia effect where
       * the map builds momentum while dragging and continues moving in the
       * same direction for some time. Feels especially nice on touch devices.
       * Enabled by default unless running on old Android devices.
       * Defaults to `*`.
       *
       * @argument inertia
       * @type {Boolean}
       */
      'inertia',
      /**
       * The rate with which the inertial movement slows down, in pixels/second².
       * Defaults to `3000`.
       *
       * @argument inertiaDeceleration
       * @type {Number}
       */
      'inertiaDeceleration',
      /**
       * Max speed of the inertial movement, in pixels/second.
       * Defaults to `Infinity`.
       *
       * @argument inertiaMaxSpeed
       * @type {Number}
       */
      'inertiaMaxSpeed',
      /**
       * Defaults to `0.2`.
       *
       * @argument easeLinearity
       * @type {Number}
       */
      'easeLinearity',
      /**
       * With this option enabled, the map tracks when you pan to another "copy"
       * of the world and seamlessly jumps to the original one so that all overlays
       * like markers and vector layers are still visible.
       * Defaults to `false`.
       *
       * @argument worldCopyJump
       * @type {Boolean}
       */
      'worldCopyJump',
      /**
       * If maxBounds is set, this option will control how solid the bounds are when
       * dragging the map around. The default value of 0.0 allows the user to drag outside
       * the bounds at normal speed, higher values will slow down map dragging outside bounds,
       * and 1.0 makes the bounds fully solid, preventing the user from dragging outside the bounds.
       * Defaults to `0.0`.
       *
       * @argument maxBoundsViscosity
       * @type {Number}
       */
      'maxBoundsViscosity',
      // Control options

      /**
       * Whether a attribution control is added to the map by default.
       * Defaults to `true`.
       *
       * @argument attributionControl
       * @type {Boolean}
       */
      'attributionControl',
      /**
       * Whether a zoom control is added to the map by default.
       * Defaults to `true`.
       *
       * @argument zoomControl
       * @type {Boolean}
       */
      'zoomControl',
      // Animation options

      /**
       * Whether the map zoom animation is enabled. By default it's
       * enabled in all browsers that support CSS3 Transitions except Android.
       * Defaults to `true`.
       *
       * @argument zoomAnimation
       * @type {Boolean}
       */
      'zoomAnimation',
      /**
       * Won't animate zoom if the zoom difference exceeds this value.
       * Defaults to `4`.
       *
       * @argument zoomAnimationThreshold
       * @type {Number}
       */
      'zoomAnimationThreshold',
      /**
       * Whether the tile fade animation is enabled. By default it's
       * enabled in all browsers that support CSS3 Transitions except Android.
       * Defaults to `true`.
       *
       * @argument fadeAnimation
       * @type {Boolean}
       */
      'fadeAnimation',
      /**
       * Whether markers animate their zoom with the zoom animation, if disabled they will
       * disappear for the length of the animation. By default it's enabled in all
       * browsers that support CSS3 Transitions except Android.
       * Defaults to `true`.
       *
       * @argument markerZoomAnimation
       * @type {Boolean}
       */
      'markerZoomAnimation',
      /**
       * Defines the maximum size of a CSS translation transform.
       * The default value should not be changed unless a web browser positions
       * layers in the wrong place after doing a large panBy.
       * Defaults to `2^23`.
       *
       * @argument transform3DLimit
       * @type {Boolean}
       */
      'transform3DLimit']);
      // Events this map can respond to.
      _defineProperty(this, "leafletEvents", [...this.leafletEvents,
      // Layer events

      /**
       * Fired when the base layer is changed through the layers control.
       *
       * @argument onBaselayerchange
       * @type {Function}
       */
      'baselayerchange',
      /**
       * Fired when an overlay is selected through the layers control.
       *
       * @argument onOverlayadd
       * @type {Function}
       */
      'overlayadd',
      /**
       * Fired when an overlay is deselected through the layers control.
       *
       * @argument onOverlayremove
       * @type {Function}
       */
      'overlayremove',
      /**
       * Fired when a new layer is added to the map.
       *
       * @argument onLayeradd
       * @type {Function}
       */
      'layeradd',
      /**
       * Fired when some layer is removed from the map
       *
       * @argument onLayerremove
       * @type {Function}
       */
      'layerremove',
      // Map state change events

      /**
       * Fired when the number of zoomlevels on the map is changed due to adding or removing a layer.
       *
       * @argument onZoomlevelschange
       * @type {Function}
       */
      'zoomlevelschange',
      /**
       * Fired when the map is resized.
       *
       * @argument onResize
       * @type {Function}
       */
      'resize',
      /**
       * Fired when the map is destroyed with remove method.
       *
       * @argument onUnload
       * @type {Function}
       */
      'unload',
      /**
       * Fired when the map needs to redraw its content
       * (this usually happens on map zoom or load).
       * Very useful for creating custom overlays.
       *
       * @argument onViewreset
       * @type {Function}
       */
      'viewreset',
      /**
       * Fired when the map is initialized (when its center and zoom are set for the first time).
       *
       * @argument onLoad
       * @type {Function}
       */
      'load',
      /**
       * Fired when the map zoom is about to change (e.g. before zoom animation).
       *
       * @argument onZoomstart
       * @type {Function}
       */
      'zoomstart',
      /**
       * Fired when the view of the map starts changing (e.g. user starts dragging the map).
       *
       * @argument onMovestart
       * @type {Function}
       */
      'movestart',
      /**
       * Fired repeatedly during any change in zoom level, including zoom and fly animations.
       *
       * @argument onZoom
       * @type {Function}
       */
      'zoom',
      /**
       * Fired repeatedly during any movement of the map, including pan and fly animations.
       *
       * @argument onMove
       * @type {Function}
       */
      'move',
      /**
       * Fired when the map has changed, after any animations.
       *
       * @argument onZoomend
       * @type {Function}
       */
      'zoomend',
      /**
       * Fired when the center of the map stops changing
       * (e.g. user stopped dragging the map).
       *
       * @argument onMoveend
       * @type {Function}
       */
      'moveend',
      // Popup events

      /**
       * Fired when the map starts autopanning when opening a popup.
       *
       * @argument onAutopanstart
       * @type {Function}
       */
      'autopanstart',
      // Location events

      /**
       * Fired when geolocation (using the locate method) failed.
       *
       * @argument onLocationerror
       * @type {Function}
       */
      'locationerror',
      /**
       * Fired when geolocation (using the locate method) went successfully.
       *
       * @argument onLocationfound
       * @type {Function}
       */
      'locationfound',
      // Interaction events

      /**
       * Fired when the user clicks (or taps) the map.
       *
       * @argument onClick
       * @type {Function}
       */
      'click',
      /**
       * Fired when the user double-clicks (or double-taps) the map.
       *
       * @argument onDblclick
       * @type {Function}
       */
      'dblclick',
      /**
       * Fired when the user pushes the mouse button on the map.
       *
       * @argument onMousedown
       * @type {Function}
       */
      'mousedown',
      /**
       * Fired when the user releases the mouse button on the map.
       *
       * @argument onMouseup
       * @type {Function}
       */
      'mouseup',
      /**
       * Fired when the mouse enters the map.
       *
       * @argument onMouseover
       * @type {Function}
       */
      'mouseover',
      /**
       * Fired when the mouse leaves the map.
       *
       * @argument onMouseout
       * @type {Function}
       */
      'mouseout',
      /**
       * Fired while the mouse moves over the map.
       *
       * @argument onMousemove
       * @type {Function}
       */
      'mousemove',
      /**
       * Fired when the user pushes the right mouse button on the map,
       * prevents default browser context menu from showing if there are
       * listeners on this event. Also fired on mobile when the user holds
       * a single touch for a second (also called long press).
       *
       * @argument onContextmenu
       * @type {Function}
       */
      'contextmenu',
      /**
       * Fired when the user presses a key from the keyboard that produces
       * a character value while the map is focused.
       *
       * @argument onKeypress
       * @type {Function}
       */
      'keypress',
      /**
       * Fired when the user presses a key from the keyboard while the map is focused.
       * Unlike the keypress event, the keydown event is fired for keys that produce
       * a character value and for keys that do not produce a character value.
       *
       * @argument onKeydown
       * @type {Function}
       */
      'keydown',
      /**
       * Fired when the user releases a key from the keyboard while the map is focused.
       *
       * @argument onKeyup
       * @type {Function}
       */
      'keyup',
      /**
       * Fired before mouse click on the map (sometimes useful when you want something to happen on click before any existing click handlers start running).
       *
       * @argument onPreclick
       * @type {Function}
       */
      'preclick',
      // Other events

      /**
       * Fired at least once per zoom animation. For continuous zoom, like pinch zooming,
       * fired once per frame during zoom.
       *
       * @argument onZoomanim
       * @type {Function}
       */
      'zoomanim']);
      _defineProperty(this, "leafletDescriptors", [...this.leafletDescriptors, 'zoom:setZoom:zoomPanOptions', 'minZoom', 'maxZoom', 'center:panTo:zoomPanOptions', 'bounds:fitBounds:fitBoundsOptions', 'maxBounds']);
      _defineProperty(this, "componentsToYield", [...this.componentsToYield, ...this.emberLeaflet.components, {
        name: 'tile-layer',
        as: 'tile',
        component: _tileLayer.default
      }, {
        name: 'wms-tile-layer',
        as: 'wms-tile',
        component: _wmsTileLayer.default
      }, {
        name: 'marker-layer',
        as: 'marker',
        component: _markerLayer.default
      }, {
        name: 'circle-layer',
        as: 'circle',
        component: _circleLayer.default
      }, {
        name: 'circle-marker-layer',
        as: 'circle-marker',
        component: _circleMarkerLayer.default
      }, {
        name: 'image-layer',
        as: 'image',
        component: _imageLayer.default
      }, {
        name: 'video-layer',
        as: 'video',
        component: _videoLayer.default
      }, {
        name: 'polyline-layer',
        as: 'polyline',
        component: _polylineLayer.default
      }, {
        name: 'polygon-layer',
        as: 'polygon',
        component: _polygonLayer.default
      }, {
        name: 'geojson-layer',
        as: 'geojson',
        component: _geojsonLayer.default
      }, {
        name: 'rectangle-layer',
        as: 'rectangle',
        component: _rectangleLayer.default
      }]);
      // required to supress glimmer component error message for acessing bounds property
      _defineProperty(this, "bounds", undefined);
    }
    mergeComponents(obj) {
      if (!this.mergedComponents) {
        this.mergedComponents = obj;
      } else {
        Object.assign(this.mergedComponents, obj);
      }
    }
    get center() {
      if (this.args.center) {
        return this.args.center;
      } else if (!this.fastboot?.isFastBoot) {
        let [lat, lng] = [this.args.lat, this.args.lng];
        return this.L.latLng(lat, lng);
      }
      return null;
    }

    // By default all layers try to register in a container layer.
    // It is not the case of the map itself as it is the topmost container.
    registerWithParent() {}
    unregisterWithParent() {}
    createLayer(element) {
      let options = this.options;

      // Don't set center and zoom right now.
      // Let base layer bind the events first
      delete options.center;
      delete options.zoom;
      return this.L.map(element, options);
    }

    // Manually call `remove` method in the case of the root map layer.
    willDestroyParent() {
      let layer = this._layer;
      super.willDestroyParent(...arguments);
      layer.remove();
    }
    didCreateLayer() {
      // after base layer bound the events, we can now set the map's view
      (true && !(this.args.bounds && !this.center && this.args.zoom === undefined || !this.args.bounds && this.center && this.args.zoom !== undefined) && (0, _debug.assert)('You must provide either valid `bounds` or a `center` (or `lat`/`lng`) and a `zoom` value.', this.args.bounds && !this.center && this.args.zoom === undefined || !this.args.bounds && this.center && this.args.zoom !== undefined));
      if (this.args.bounds) {
        this._layer.fitBounds(this.args.bounds, Object.assign({
          reset: true
        }, this.args.fitBoundsOptions));
      } else {
        this._layer.setView(this.center, this.args.zoom, Object.assign({
          reset: true
        }, this.args.zoomPanOptions));
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "emberLeaflet", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "mergeComponents", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "mergeComponents"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "willDestroyParent", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroyParent"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LeafletMap);
});