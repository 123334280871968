define("ember-leaflet/components/div-overlay-layer", ["exports", "@ember/component", "@glimmer/tracking", "ember-leaflet/components/base-layer", "@ember/template-factory"], function (_exports, _component, _tracking, _baseLayer, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.popupOpenDidChange}}
    {{did-update this.popupOpenDidChange @popupOpen}}
  {{/if}}
  
  {{#each this.leafletDescriptorsProps as |prop|}}
    {{did-update (fn this.updateOption prop) (get this.args prop)}}
  {{/each}}
  
  <@node @didInsertParent={{this.didInsertParent}} @willDestroyParent={{this.willDestroyParent}}>
    {{#unless this.fastboot.isFastBoot}}
      {{#if this.shouldRender}}
        {{#in-element this.destinationElement~}}
          {{#if this.closePopup}}
            {{yield this.closePopup}}
          {{else}}
            {{yield}}
          {{/if}}
        {{~/in-element}}
      {{/if}}
    {{/unless}}
  </@node>
  */
  {
    "id": "RJMkqm7q",
    "block": "[[[41,[30,0,[\"popupOpenDidChange\"]],[[[1,\"  \"],[1,[28,[35,1],[[30,0,[\"popupOpenDidChange\"]],[30,1]],null]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"leafletDescriptorsProps\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,1],[[28,[37,4],[[30,0,[\"updateOption\"]],[30,2]],null],[28,[37,5],[[30,0,[\"args\"]],[30,2]],null]],null]],[1,\"\\n\"]],[2]],null],[1,\"\\n\"],[8,[30,3],null,[[\"@didInsertParent\",\"@willDestroyParent\"],[[30,0,[\"didInsertParent\"]],[30,0,[\"willDestroyParent\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[51,[30,0,[\"fastboot\",\"isFastBoot\"]]],[[[41,[30,0,[\"shouldRender\"]],[[[40,[[[41,[30,0,[\"closePopup\"]],[[[1,\"          \"],[18,4,[[30,0,[\"closePopup\"]]]],[1,\"\\n\"]],[]],[[[1,\"          \"],[18,4,null],[1,\"\\n\"]],[]]]],[]],\"%cursor:0%\",[28,[37,8],[[30,0,[\"destinationElement\"]]],null]]],[]],null]],[]],null]],[]]]]]],[\"@popupOpen\",\"prop\",\"@node\",\"&default\"],false,[\"if\",\"did-update\",\"each\",\"-track-array\",\"fn\",\"get\",\"unless\",\"in-element\",\"-in-el-null\",\"yield\"]]",
    "moduleName": "ember-leaflet/components/div-overlay-layer.hbs",
    "isStrictMode": false
  });

  /**
   * A base class for PopupLayer and TooltipLayer.
   *
   * @class DivOverlayLayer
   * @extends BaseLayer
   */
  let DivOverlayLayer = _exports.default = (_class = class DivOverlayLayer extends _baseLayer.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "destinationElementTag", 'div');
      _defineProperty(this, "destinationElement", document.createElement(this.destinationElementTag));
      _initializerDefineProperty(this, "shouldRender", _descriptor, this);
      _defineProperty(this, "leafletOptions", [...this.leafletOptions,
      /**
       * The offset of the popup position. Useful to control the anchor of the popup when
       * opening it on some overlays. Defaults to `Point(0, 7)`.
       *
       * @argument offset
       * @type {Point}
       */
      'offset',
      /**
       * A custom CSS class name to assign to the popup.
       *
       * @argument className
       * @type {String}
       */
      'className',
      /**
       * Map pane where the overlay layer will be added.
       *
       * @argument pane
       * @type {String}
       */
      'pane']);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "shouldRender", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DivOverlayLayer);
});