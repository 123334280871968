define("ember-power-calendar/components/power-calendar-multiple", ["exports", "ember-power-calendar/components/power-calendar", "@ember/object", "ember-power-calendar-utils", "@ember/debug", "@ember/array"], function (_exports, _powerCalendar, _object, _emberPowerCalendarUtils, _debug, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class2;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let _class = _exports.default = (_dec = (0, _object.computed)('center'), (_class2 = class _class2 extends _powerCalendar.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "daysComponent", 'power-calendar-multiple/days');
      _defineProperty(this, "_calendarType", 'multiple');
    }
    // CPs
    get selected() {
      return undefined;
    }
    set selected(v) {
      return (0, _array.isArray)(v) ? v.map(_emberPowerCalendarUtils.normalizeDate) : v;
    }
    get currentCenter() {
      let center = this.center;
      if (!center) {
        center = (this.selected || [])[0] || this.powerCalendarService.getDate();
      }
      return (0, _emberPowerCalendarUtils.normalizeDate)(center);
    }

    // Actions
    select(dayOrDays, calendar, e) {
      (true && !((0, _array.isArray)(dayOrDays) || dayOrDays instanceof Object && dayOrDays.date instanceof Date) && (0, _debug.assert)(`The select action expects an array of date objects, or a date object. ${typeof dayOrDays} was recieved instead.`, (0, _array.isArray)(dayOrDays) || dayOrDays instanceof Object && dayOrDays.date instanceof Date));
      let days;
      if ((0, _array.isArray)(dayOrDays)) {
        days = dayOrDays;
      } else if (dayOrDays instanceof Object && dayOrDays.date instanceof Date) {
        days = [dayOrDays];
      }
      if (this.onSelect) {
        this.onSelect(this._buildCollection(days), calendar, e);
      }
    }

    // Methods
    _buildCollection(days) {
      let selected = this.publicAPI.selected || [];
      for (let day of days) {
        let index = selected.findIndex(selectedDate => (0, _emberPowerCalendarUtils.isSame)(day.date, selectedDate, "day"));
        if (index === -1) {
          selected = [...selected, day.date];
        } else {
          selected = selected.slice(0, index).concat(selected.slice(index + 1));
        }
      }
      return (0, _emberPowerCalendarUtils.normalizeMultipleActionValue)({
        date: selected
      });
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "selected", [_object.computed], Object.getOwnPropertyDescriptor(_class2.prototype, "selected"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "currentCenter", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "currentCenter"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "select", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "select"), _class2.prototype)), _class2));
});